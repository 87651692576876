document.addEventListener("DOMContentLoaded", () => {
  const initGallery = function(){
    lightGallery(document.getElementById("gallery"), {
      plugins: [lgThumbnail],
      download: false,
      speed: 200,
      subHtmlSelectorRelative: true,
      mobileSettings: {
          controls: false,
          download: false,
          showCloseIcon: true
        }
    });
  }
  
  const loadGalleryScripts = function(){
    const promises = []
    promises.push(import('./lightgallery/lightgallery.min.js'));
    promises.push(import('./lightgallery/lg-thumbnail.min.js'));
  
    Promise.all(promises)
    .then(function(e){
      window.lightGallery = window.lightGallery || e[0];
      window.lgThumbnail = window.lgThumbnail || e[1];
      initGallery();
    });
  }

  loadGalleryScripts();
});
